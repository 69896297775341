import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import DesJeepicon from "../../assets/desJeepicon.png"
import { countries } from "../../GeneralComponents/Countries";
import { useNavigate } from "react-router-dom";
import { BASE_URL, get } from "../../services/Calls";
import GreeceFlag from '../../assets/greeceFlag.png'


interface IProps {
  pageFrom: any;
}

const MostPopular = ({
  pageFrom
}: IProps) => {

  const navigate = useNavigate();
  // const [tripsData,setTripsData] = useState([]);
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);
  const [tripsData, setTripsData] = useState([])

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, []);


  useEffect(() => {
    getTrips()
  }, [])


  const getTrips = async () => {
    try {
      if(localStorage.getItem("token"))
      {
      const response = await get(BASE_URL + "/trips?personalized=false");

      if (response && response.results && response.results.length > 0) {
        if (pageFrom == 'landing') {
          let tempTripData:any = []
          for (let i = 0; i < response.results.length; i++) {
            if (response.results[i].most_popular == true) {
              tempTripData.push(response.results[i])
            }
          }
          setTripsData(tempTripData)
        }
        else {
          const encodedString = window.location.pathname;
          const decodedString = decodeURIComponent(encodedString.replace('/', ''));
          let tempTripData:any = []
          for (let i = 0; i < response.results.length; i++) {
            if (response.results[i].country.name == decodedString) {
              tempTripData.push(response.results[i])
            }
          }
          setTripsData(tempTripData)
        }
      }
    }
    }
    catch (ex) {
      //alert(ex)
console.log('error',ex)
    }
  };



  return (
    <div>
      {tripsData && tripsData.length > 0 &&
      <div className="mostPopularMainContainer" style={{ paddingTop: "1.25vw" }}>
        {pageFrom == 'landing' &&
          <div className="mostPopularHeadingdiv">
            <div>Most popular</div>
          </div>
        }
        <div className={pageFrom == 'landing' ? "mostPopularGridViewLanding" : "mostPopularGridView"}>
          { (pageFrom == 'landing' ? tripsData.slice(0, 4) : tripsData).map((trip: any, index: any) => (
            <div style={{ width: '18.25vw' }} onClick={() => { navigate('/'+trip.country.name+'/'+trip.name, { state: { trip: trip } }) }}>
              {trip && trip.country && trip.country.flagURL &&
                <div className="mostPopularFlagDiv">
                  <img src={trip.country.flagURL} />
                </div>
              }
              <div className="mostPopularItemDiv" style={{ backgroundImage: `url(${trip.image})` }}>
                <div className="mostPopularItemOverlay">

                </div>
              </div>
              <div className="mostPopularItemFooterDiv">
                <div>
                  <div className="mostPopularItemName">
                    {/* The Balkan Ranges */}
                    {trip.name}
                  </div>
                  <div className="mostPopularCountryAndDaysDiv">
                    {trip && trip.country && trip.country.name && <>
                      <span>{/* Country */}{trip.country.name}</span>
                    </>
                    }
                    {trip && trip.days && trip.days.length > 0 &&
                      <>
                        <span className="mostPopularCountryDaysDivider"></span>
                        <span>{trip.days.length} days</span>
                      </>
                    }
                  </div>
                </div>
                <div>
                  <img className="mostPopularJeepIcon" src={DesJeepicon} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
}
    </div>
  )
}
export default MostPopular;
